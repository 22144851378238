export const BG_MUSIC = "BG_MUSIC";
export const BUY_TICKETS = "BUY_TICKETS";
export const WELCOME = "WELCOME";
export const NOTIFICATION = "NOTIFICATION";
export const NUMBER_CALL = "NUMBER_CALL";
export const NUMBER_MATCH = "NUMBER_MATCH";
export const WIN_DIALOG = "WIN_DIALOG";
export const BUTTON_CLICK = "BUTTON_CLICK";
export const BEST_OF_LUCK_EVERYONE = "BestOfLuckEveryone";
export const BEST_OF_LUCK_NEXT_TIME = "BestOfLuckNextTime";
export const CONGRATS_ALL_WINNERS = "CongratsToAllWinners";
export const WE_HAVE_A_WINNER = "WeHaveAWinner";
export const JACKPOT_WINNER = "Jackpot";

export default {
  BG_MUSIC,
  BUY_TICKETS,
  WELCOME,
  NOTIFICATION,
  NUMBER_CALL,
  NUMBER_MATCH,
  WIN_DIALOG,
  BUTTON_CLICK,
  BEST_OF_LUCK_EVERYONE,
  BEST_OF_LUCK_NEXT_TIME,
  CONGRATS_ALL_WINNERS,
  WE_HAVE_A_WINNER,
  JACKPOT_WINNER,
};
