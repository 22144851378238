import { createAction } from "redux-actions";
import config from "../../config";

import { PLAY_SOUND, STOP_SOUND, MUTE_SOUNDS, UNMUTE_SOUNDS } from "./types";

import {
  BG_MUSIC,
  WELCOME,
  BUY_TICKETS,
  NOTIFICATION,
  NUMBER_CALL,
  NUMBER_MATCH,
  WIN_DIALOG,
  BUTTON_CLICK,
  BEST_OF_LUCK_EVERYONE,
  BEST_OF_LUCK_NEXT_TIME,
  CONGRATS_ALL_WINNERS,
  WE_HAVE_A_WINNER,
  JACKPOT_WINNER,
} from "../../constants/sounds";

const createSoundActionMetaCreator = (extraMetaData) => (payload) => ({
  throttle: {
    time: config.game.soundThrottleTime,
  },
  ...extraMetaData,
});

export const stopBGMusic = createAction(STOP_SOUND, () => BG_MUSIC);
export const playBGMusic = createAction(
  PLAY_SOUND,
  () => BG_MUSIC,
  createSoundActionMetaCreator({ playOnLoad: true, playProps: { loop: true } })
);
export const playWelcomeSound = createAction(
  PLAY_SOUND,
  () => WELCOME,
  createSoundActionMetaCreator({ playOnLoad: true })
);
export const playBuyTicketsSound = createAction(PLAY_SOUND, () => BUY_TICKETS, createSoundActionMetaCreator());
export const playNotificationSound = createAction(PLAY_SOUND, () => NOTIFICATION, createSoundActionMetaCreator());
export const playNumberCallSound = createAction(PLAY_SOUND, () => NUMBER_CALL, createSoundActionMetaCreator());
export const playNumberMatchSound = createAction(PLAY_SOUND, () => NUMBER_MATCH, createSoundActionMetaCreator());
export const playWinDialogSound = createAction(PLAY_SOUND, () => WIN_DIALOG, createSoundActionMetaCreator());
export const playButtonClickSound = createAction(PLAY_SOUND, () => BUTTON_CLICK, createSoundActionMetaCreator());
export const playBestOfLuckEveryone = createAction(
  PLAY_SOUND,
  () => BEST_OF_LUCK_EVERYONE,
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playBestOfLuckNextTime = createAction(
  PLAY_SOUND,
  () => BEST_OF_LUCK_NEXT_TIME,
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playCongratsAllWinners = createAction(
  PLAY_SOUND,
  () => CONGRATS_ALL_WINNERS,
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playWeHaveAWinner = createAction(
  PLAY_SOUND,
  () => WE_HAVE_A_WINNER,
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playJackpotWinner = createAction(
  PLAY_SOUND,
  () => JACKPOT_WINNER,
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber01 = createAction(
  PLAY_SOUND,
  () => "Number_01",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber02 = createAction(
  PLAY_SOUND,
  () => "Number_02",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber03 = createAction(
  PLAY_SOUND,
  () => "Number_03",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber04 = createAction(
  PLAY_SOUND,
  () => "Number_04",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber05 = createAction(
  PLAY_SOUND,
  () => "Number_05",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber06 = createAction(
  PLAY_SOUND,
  () => "Number_06",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber07 = createAction(
  PLAY_SOUND,
  () => "Number_07",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber08 = createAction(
  PLAY_SOUND,
  () => "Number_08",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber09 = createAction(
  PLAY_SOUND,
  () => "Number_09",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber10 = createAction(
  PLAY_SOUND,
  () => "Number_10",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber11 = createAction(
  PLAY_SOUND,
  () => "Number_11",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber12 = createAction(
  PLAY_SOUND,
  () => "Number_12",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber13 = createAction(
  PLAY_SOUND,
  () => "Number_13",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber14 = createAction(
  PLAY_SOUND,
  () => "Number_14",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber15 = createAction(
  PLAY_SOUND,
  () => "Number_15",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber16 = createAction(
  PLAY_SOUND,
  () => "Number_16",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber17 = createAction(
  PLAY_SOUND,
  () => "Number_17",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber18 = createAction(
  PLAY_SOUND,
  () => "Number_18",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber19 = createAction(
  PLAY_SOUND,
  () => "Number_19",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber20 = createAction(
  PLAY_SOUND,
  () => "Number_20",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber21 = createAction(
  PLAY_SOUND,
  () => "Number_21",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber22 = createAction(
  PLAY_SOUND,
  () => "Number_22",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber23 = createAction(
  PLAY_SOUND,
  () => "Number_23",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber24 = createAction(
  PLAY_SOUND,
  () => "Number_24",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber25 = createAction(
  PLAY_SOUND,
  () => "Number_25",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber26 = createAction(
  PLAY_SOUND,
  () => "Number_26",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber27 = createAction(
  PLAY_SOUND,
  () => "Number_27",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber28 = createAction(
  PLAY_SOUND,
  () => "Number_28",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber29 = createAction(
  PLAY_SOUND,
  () => "Number_29",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber30 = createAction(
  PLAY_SOUND,
  () => "Number_30",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber31 = createAction(
  PLAY_SOUND,
  () => "Number_31",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber32 = createAction(
  PLAY_SOUND,
  () => "Number_32",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber33 = createAction(
  PLAY_SOUND,
  () => "Number_33",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber34 = createAction(
  PLAY_SOUND,
  () => "Number_34",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber35 = createAction(
  PLAY_SOUND,
  () => "Number_35",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber36 = createAction(
  PLAY_SOUND,
  () => "Number_36",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber37 = createAction(
  PLAY_SOUND,
  () => "Number_37",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber38 = createAction(
  PLAY_SOUND,
  () => "Number_38",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber39 = createAction(
  PLAY_SOUND,
  () => "Number_39",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber40 = createAction(
  PLAY_SOUND,
  () => "Number_40",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber41 = createAction(
  PLAY_SOUND,
  () => "Number_41",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber42 = createAction(
  PLAY_SOUND,
  () => "Number_42",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber43 = createAction(
  PLAY_SOUND,
  () => "Number_43",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber44 = createAction(
  PLAY_SOUND,
  () => "Number_44",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber45 = createAction(
  PLAY_SOUND,
  () => "Number_45",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber46 = createAction(
  PLAY_SOUND,
  () => "Number_46",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber47 = createAction(
  PLAY_SOUND,
  () => "Number_47",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber48 = createAction(
  PLAY_SOUND,
  () => "Number_48",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber49 = createAction(
  PLAY_SOUND,
  () => "Number_49",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber50 = createAction(
  PLAY_SOUND,
  () => "Number_50",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber51 = createAction(
  PLAY_SOUND,
  () => "Number_51",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber52 = createAction(
  PLAY_SOUND,
  () => "Number_52",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber53 = createAction(
  PLAY_SOUND,
  () => "Number_53",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber54 = createAction(
  PLAY_SOUND,
  () => "Number_54",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber55 = createAction(
  PLAY_SOUND,
  () => "Number_55",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber56 = createAction(
  PLAY_SOUND,
  () => "Number_56",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber57 = createAction(
  PLAY_SOUND,
  () => "Number_57",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber58 = createAction(
  PLAY_SOUND,
  () => "Number_58",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber59 = createAction(
  PLAY_SOUND,
  () => "Number_59",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber60 = createAction(
  PLAY_SOUND,
  () => "Number_60",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber61 = createAction(
  PLAY_SOUND,
  () => "Number_61",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber62 = createAction(
  PLAY_SOUND,
  () => "Number_62",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber63 = createAction(
  PLAY_SOUND,
  () => "Number_63",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber64 = createAction(
  PLAY_SOUND,
  () => "Number_64",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber65 = createAction(
  PLAY_SOUND,
  () => "Number_65",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);

export const playNumber66 = createAction(
  PLAY_SOUND,
  () => "Number_66",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);

export const playNumber67 = createAction(
  PLAY_SOUND,
  () => "Number_67",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber68 = createAction(
  PLAY_SOUND,
  () => "Number_68",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber69 = createAction(
  PLAY_SOUND,
  () => "Number_69",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber70 = createAction(
  PLAY_SOUND,
  () => "Number_70",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber71 = createAction(
  PLAY_SOUND,
  () => "Number_71",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber72 = createAction(
  PLAY_SOUND,
  () => "Number_72",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber73 = createAction(
  PLAY_SOUND,
  () => "Number_73",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber74 = createAction(
  PLAY_SOUND,
  () => "Number_74",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber75 = createAction(
  PLAY_SOUND,
  () => "Number_75",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber76 = createAction(
  PLAY_SOUND,
  () => "Number_76",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber77 = createAction(
  PLAY_SOUND,
  () => "Number_77",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber78 = createAction(
  PLAY_SOUND,
  () => "Number_78",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber79 = createAction(
  PLAY_SOUND,
  () => "Number_79",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber80 = createAction(
  PLAY_SOUND,
  () => "Number_80",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber81 = createAction(
  PLAY_SOUND,
  () => "Number_81",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber82 = createAction(
  PLAY_SOUND,
  () => "Number_82",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber83 = createAction(
  PLAY_SOUND,
  () => "Number_83",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber84 = createAction(
  PLAY_SOUND,
  () => "Number_84",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber85 = createAction(
  PLAY_SOUND,
  () => "Number_85",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber86 = createAction(
  PLAY_SOUND,
  () => "Number_86",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber87 = createAction(
  PLAY_SOUND,
  () => "Number_87",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber88 = createAction(
  PLAY_SOUND,
  () => "Number_88",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber89 = createAction(
  PLAY_SOUND,
  () => "Number_89",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);
export const playNumber90 = createAction(
  PLAY_SOUND,
  () => "Number_90",
  createSoundActionMetaCreator({ playProps: { volume: 0.8 } })
);

export const muteSounds = createAction(MUTE_SOUNDS);
export const unmuteSounds = createAction(UNMUTE_SOUNDS);
