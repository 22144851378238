import { Howler, Howl } from 'howler';
import Channel from './channel';
import soundsList from '../../assets/sounds';
import { EventEmitter } from 'events';
import { SOUND_LOADED } from './events';
import {CONGRATS_ALL_WINNERS, JACKPOT_WINNER, WE_HAVE_A_WINNER} from "../../constants/sounds";

const _sounds = {};
const _channels = {};

let _filesToLoad = 0;
let _filesLoaded = 0;
let _onAllLoaded;

class SoundService extends EventEmitter {
	isMuted = true;
	currentNumberAssetKey = null;
	currentNumberSoundId = null;

	constructor(soundsList) {
		super();
		this.loadList(soundsList);
	}

	load(assetKey, url, channel, sprite = false) {
		const sound = new Howl({
			src: url,
			sprite: sprite
		});
		sound.on('load', () => {
			this._onSingleAssetLoaded(sound);

			if (!sprite) {
				console.log("Loaded sound:", assetKey, sound, channel);
				this.add(assetKey, sound, channel);
				this.emit(SOUND_LOADED, {soundId: assetKey});
			} else {
				for (let key in sprite) {
					console.log("Loaded sprite sound:", key, sound, channel);
					this.add(key, sound, channel, true);
					this.emit(SOUND_LOADED, { soundId: key });
				}
			}
		});
		// get error from howler loader
		sound.once('loaderror', (e, msg) => {
			console.error('sounds loading error', e, msg);
		});
	}

	loadList(list, onAllLoaded) {
		if (!list || !list.length) {
			return onAllLoaded && onAllLoaded();
		}
		_onAllLoaded = onAllLoaded;
		for (let i = 0; i < list.length; i++) {
			if (!list[i].name || !list[i].url) {
				console.warn('asset name or url is not provided');
				continue;
			}

			_filesToLoad++;

			if (!!list[i].sprite) {
				this.load(list[i].name, list[i].url, list[i].channel, list[i].sprite);
			} else {
				this.load(list[i].name, list[i].url, list[i].channel);
			}
		}
	};
	_onSingleAssetLoaded() {
		_filesLoaded++;
		console.log(`sound loaded ${_filesLoaded}/${_filesToLoad}`);
		if (_filesLoaded >= _filesToLoad) {
			return _onAllLoaded && _onAllLoaded();
		}
	}

	add(soundKey, soundData, channel = 'sfx', isSprite = false) {
		if (_sounds[soundKey]) {
			return console.error('sound "' + soundKey + '" already added');
		}
		soundData.channel = channel;
		_sounds[soundKey] = { data: soundData, isSprite: isSprite };
		if (!_channels[channel]) {
			this.createChannel(channel);
		}
		_channels[channel].sounds.push(soundData);
	}

	createChannel(name) {
		if (_channels[name]) {
			return new Error('channel already exist: ' + name);
		}
		_channels[name] = new Channel();
		return _channels[name];
	}

	play(assetKey, properties) {
		if (!this.isMuted || properties.playOnLoad) {
			if (assetKey === WE_HAVE_A_WINNER || assetKey === CONGRATS_ALL_WINNERS || assetKey === JACKPOT_WINNER) {
				if (_sounds[this.currentNumberAssetKey].data.playing(this.currentNumberSoundId)) {
					_sounds[this.currentNumberAssetKey].data.once("end", () => {
						this.play(assetKey, properties);
					});

					return;
				}
			}

			const sound = _sounds[assetKey];
			if (!sound) {
				return console.error('sound "' + assetKey + '" is not added');
			}

			if (properties) {
				sound.data.volume(properties.volume === undefined ? 1 : properties.volume);
				sound.data.loop(!!properties.loop);

				if (properties.fade) {
					sound.data.fade(properties.fade.from, properties.fade.to, properties.fade.duration);
				}
			}
			const soundId = sound.data.play(sound.isSprite ? assetKey : undefined);
			if (assetKey.indexOf("Number_") !== -1) {
				this.currentNumberAssetKey = assetKey;
				this.currentNumberSoundId = soundId;
			}
			return sound;
		}
	}

	stop(assetKey, id) {
		if (!_sounds[assetKey]) {
			return console.error('sound "' + assetKey + '" is not added');
		}

		return _sounds[assetKey].data.stop(id);
	}

	pause(assetKey, id) {
		if (!_sounds[assetKey]) {
			return console.error('sound "' + assetKey + '" is not added');
		}
		return _sounds[assetKey].data.pause(id);
	}

	/**
	 * Will NOT STOP the sound. To stop sound after fade out use fadeAndStop()
	 * @param {String} assetKey
	 * @param {Object} props  - {from: 0, to: 1, duration: 1000}
	 * @param {Number} id  - Optional
	 * @returns {*}
	 */
	fade(assetKey, props, id) {
		console.log('sound fade:', assetKey);
		if (!_sounds[assetKey]) {
			return console.error('sound "' + assetKey + '" is not added');
		}
		return _sounds[assetKey].data.fade(props.from, props.to, props.duration, id);
	}

	fadeAndStop(assetKey, props, id) {
		if (!_sounds[assetKey]) {
			return console.error('sound "' + assetKey + '" is not added');
		}
		_sounds[assetKey].once('fade', () => {
			console.log('onfade', assetKey);
			_sounds[assetKey].data.stop(id);
		});
		return _sounds[assetKey].data.fade(props.from, props.to, props.duration, id);
	}

	mute(muted) {
		this.isMuted = muted;
		Howler.mute(muted);

		if (window.parent)
			window.parent.postMessage(muted ? 'SOUND_DISABLED' : 'SOUND_ENABLED', '*');
	}

	muteChannel(channelName, muted) {
		if (!_channels[channelName]) {
			return false;
		}
		_channels[channelName].mute(muted);
	}

	// Game loop related method
	onGameLoopPause() {
		let sound;
		for (let soundName in _sounds) {
			if (!_sounds.hasOwnProperty(soundName)) {
				return;
			}

			sound = _sounds[soundName];
			if (sound.data.playing()) {
				sound.data.pausedByGameLoop = true;
				sound.data.pause();
			}
		}
	}

	onGameLoopContinue() {
		let sound;
		for (let soundName in _sounds) {
			if (!_sounds.hasOwnProperty(soundName)) {
				return;
			}

			sound = _sounds[soundName];
			if (sound.data.pausedByGameLoop) {
				sound.data.pausedByGameLoop = false;
				sound.data.play();
			}
		}
	}
}

export default new SoundService(soundsList);
