import {
    FETCH_INITIAL_DATA,
    PURCHASE_CARDS,
    GENERATE_CARDS
} from '../actions/types';

import {
    errorNotification,
    incrementReconnectAttempts,
    resetReconnectAttempts,
    fetchInitialData,
    showLoader,
    hideLoader
} from '../actions';

import HTTPTransport from '../../transports/HTTPTransport';
import EC from '../../constants/errorCodes';
import config from '../../config';
import { uid } from '../../utils';
import { HTTP_REQUEST } from '../../constants/specialActionsMetaTypes';

const { server: { reconnects: { delayBetweenAttempts, maxAttempts } } } = config;

const HTTP_REQUEST_ERROR = 'HTTPRequestError';
const PENDING = 'pending';
const FAILED = 'failed';

export default store => {
    const reconnectAction = fetchInitialData;
    let requestsQueue = {};

    const invokeTransportCall = ({ type, payload }) => {
        const { sessionId, gameId } = store.getState().app;

        switch (type) {
            default:
                break;
            case FETCH_INITIAL_DATA:
                return HTTPTransport.fetchInitialData(sessionId, gameId);
            case PURCHASE_CARDS:
                const [cardsIds] = payload;
                return HTTPTransport.purchaseCards(sessionId, gameId, cardsIds);
            case GENERATE_CARDS:
                const [count] = payload;
                return HTTPTransport.generateCards(sessionId, gameId, count);
        }
    };

    const createRequestAction = (actionType, data) => ({ type: actionType, payload: data });

    const validateAction = action => action.meta && action.meta.type === HTTP_REQUEST;

    const dispatchRequestSuccessActions = () => store.dispatch(resetReconnectAttempts());

    const resendFailedRequests = () => {
        const actionsData = Object.values(requestsQueue);
        requestsQueue = {};
        actionsData.forEach(item => {
            if (item.status === FAILED) {
                store.dispatch(item.action);
            }
        })
    }

    const addToRequestsQueue = (requestId, action) => {
        requestsQueue[requestId] = { action, status: PENDING };
    }

    const removeRequestFromQueue = requestId => {
        delete requestsQueue[requestId];
    }

    const markRequestAsFailed = requestId => {
        if (requestsQueue[requestId]) {
            requestsQueue[requestId].status = FAILED;
        }
    }

    const sendRequest = async action => {
        const [successActionType, failureActionType] = action.meta.handleActionsTypes;

        try {
            const result = await invokeTransportCall(action);
            store.dispatch(createRequestAction(successActionType, result));
            dispatchRequestSuccessActions();
        }
        catch (err) {
            const { response } = err;
            store.dispatch(createRequestAction(failureActionType, response));
            handleErrors(response.id);
            throw new Error(HTTP_REQUEST_ERROR)
        }
    }

    const processAction = async action => {
        const requestId = uid();

        try {
            if (!action.meta.initial) {
                addToRequestsQueue(requestId, action);
            }

            await sendRequest(action);
            removeRequestFromQueue(requestId);
            resendFailedRequests();
        }
        catch (err) {
            markRequestAsFailed(requestId);
        }
    }

    const handleErrors = id => {
        const { getState, dispatch } = store;

        switch (id) {
            case EC.CONNECTION_ERROR:
                if (getState().app.reconnectAttempts < maxAttempts) {
                    const reconnectTimeoutId = scheduleReconnect();
                    dispatch(incrementReconnectAttempts(reconnectTimeoutId));
                    dispatch(showLoader());
                }
                else {
                    dispatch(hideLoader());
                    dispatch(errorNotification(EC.CONNECTION_LOST));
                }
                break;
            case EC.GAME_NOT_FOUND:
            case EC.NOT_AUTHORIZED:
                dispatch(errorNotification(id));
                break;
            default:
                dispatch(errorNotification(EC.UNKNOWN_SERVER_ERROR));
                break;
        }
    }

    const scheduleReconnect = () => setTimeout(() => {
        store.dispatch(reconnectAction());
    }, delayBetweenAttempts);

    return next => action => {
        if (validateAction(action)) {
            processAction(action);
        }

        next(action);
    }
}

