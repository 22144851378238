const CLOSE = 'CLOSE';
const SHOW_INFO = 'SHOW_INFO';

const send = type => {
    //Safari mobile browser workaround
    try {
        if (window.parent) {
            window.parent.postMessage(type, '*');
        }
    }
    catch (e) {
        if (window.parent) {
            window.parent.postMessage(type, '*');
        }
    }
};

const close = () => send(CLOSE);
const showInfo = () => send(SHOW_INFO);

export default { close, showInfo };

