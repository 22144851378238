import { combineReducers } from 'redux';
import appReducer from "./appReducer";
import gameReducer from "./gameReducer";
import dataReducer from "./dataReducer";

export default combineReducers({
    app: appReducer,
    game: gameReducer,
    data: dataReducer
});

