
import { uid } from '../../utils';
import { updateDialogsQueue } from '../actions';

import { WIN_PATTERN, JACKPOT, END_GAME, ERROR_NOTIFICATION, SHOW_PURCHASE_DIALOG } from '../actions/types';
import { ERROR, PERSONAL_WIN, PLAYER_WIN, WINNERS_LIST, PURCHASE } from '../../constants/dialogsTypes';

export default store => {
    const processActions = action => {
        let dialogData;
        const { playerId } = store.getState().data.user;
        const { nicknamesOfPlayers } = store.getState().data;

        switch (action.type) {
            default:
                break;
            case WIN_PATTERN:
            case JACKPOT:
                dialogData = getWinDialogData(playerId, action.payload.winList, action.payload.patternName, nicknamesOfPlayers, action.type === JACKPOT);
                break;
            case END_GAME:
                dialogData = getWinnersListDialogData(action.payload.lobby.winners, nicknamesOfPlayers, action.type === JACKPOT);
                break
            case ERROR_NOTIFICATION:
                dialogData = getErrorDialogData(action.payload);
                break;
            case SHOW_PURCHASE_DIALOG:
                dialogData = getPurchaseDialogData();
                break;
        }

        if (dialogData) {
            store.dispatch(updateDialogsQueue(assignId(dialogData)));
        }
    }

    return next => action => {
        processActions(action);
        next(action);
    }
};

const assignId = data => ({ ...data, id: uid() });

const getPurchaseDialogData = () => ({ type: PURCHASE });

const getErrorDialogData = errorCode => ({ type: ERROR, errorCode });

const getWinDialogData = (userPlayerId, winList, pattern, nicknamesOfPlayers, jackpot = false) => {
    const playersIds = getPlayersIds(winList);

    const dialogData = playersIds.size === 1 ? getSinglePlayerWinDialogData(userPlayerId, winList[0], nicknamesOfPlayers) :
        getPlayersWinDialogData(playersIds, userPlayerId, winList, nicknamesOfPlayers);

    return { ...dialogData, pattern, jackpot };
};

const getPlayersWinDialogData = (playersIds, userPlayerId, winList, nicknamesOfPlayers) => {
    const list = getPlayersList(playersIds, userPlayerId, winList, nicknamesOfPlayers);

    if (winList.find(item => item.playerId === userPlayerId)) {
        return {
            nickname: nicknamesOfPlayers[userPlayerId],
            type: PERSONAL_WIN,
            prize: winList.find(item => item.playerId === userPlayerId).prize,
            list,
            shared: true
        }
    } else {
        return {
            type: WINNERS_LIST,
            list,
            summary: false
        };
    }
}

const getSinglePlayerWinDialogData = (userPlayerId, { playerId, prize }, nicknamesOfPlayers) => {
    return {
        nickname: nicknamesOfPlayers[playerId],
        type: playerId === userPlayerId ? PERSONAL_WIN : PLAYER_WIN,
        prize
    };
}

const getPlayersIds = winList => winList.reduce((total, current) => {
    total.add(current.playerId);
    return total;
}, new Set());

const getPlayersList = (playersIds, userPlayerId, winList, nicknamesOfPlayers) => {
    return Array.from(playersIds)
        .filter(item => item !== userPlayerId)
        .map(id => ({
            playerId: id,
            nickname: nicknamesOfPlayers[id],
            prize: winList.find(item => item.playerId === id).prize
        }))
        .sort((a, b) => b.prize - a.prize);
}

const mergeWinnersPrizes = (total, { token, prizeAllocation }) =>
    ({ ...total, [token]: total[token] ? total[token] + prizeAllocation : prizeAllocation });

const mergeWinnersLists = (total, [key, value]) => ({ ...total, [key]: total[key] ? total[key] + value : value });

const getWinnersList = winners => Object.keys(winners)
    .map(key => winners[key]
        .reduce(mergeWinnersPrizes, {}))
    .reduce((total, current) => Object.entries(current)
        .reduce(mergeWinnersLists, { ...total }), {});

const getWinnersListDialogData = (winners, nicknamesOfPlayers, jackpot) => {
    const list = Object.entries(getWinnersList(winners))
        .map(([key, value]) => ({ playerId: key, prize: value, nickname: nicknamesOfPlayers[key] }))
        .sort((a, b) => b.prize - a.prize);

    return { type: WINNERS_LIST, list, jackpot, summary: true };
};
