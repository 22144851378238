export default store => {
    const handleErrors = action => {
        if (action.error) {
            if (process.env.NODE_ENV === 'development') {
                console.log('ERROR', action.payload);
            }
        }
    };

    return next => action => {
        handleErrors(action);
        next(action);
    };
};