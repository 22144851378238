import React, { PureComponent } from 'react';
import { Window, FlexColumn, LoadingBarBackground, LoadingBar, PreloadBackground } from './styles';
import { SafeZoneContainer } from '../../shared/styles';
import { ReactComponent as LogoAsset } from './assets/tournament_logo.svg';

export default class Preloader extends PureComponent {
    componentWillUnmount() {
        const { onUnmount } = this.props;

        if (onUnmount) {
            onUnmount();
        }
    }

    render() {
        const { progress } = this.props;
        return (
            <Window>
                <PreloadBackground>
                    <SafeZoneContainer>
                        <FlexColumn>
                            <LogoAsset />
                            <LoadingBarBackground>
                                <LoadingBar progress={progress} />
                            </LoadingBarBackground>
                        </FlexColumn>
                    </SafeZoneContainer>
                </PreloadBackground>
            </Window>
        )
    }
}
