import config from '../config';

class WebSocketTransport {
    constructor(wsUrl) {
        this.wsUrl = wsUrl;
    }

    setup(sessionId, dispatchMessage, dispatchError) {
        this.sessionId = sessionId
        this.dispatchMessage = dispatchMessage;
        this.dispatchError = dispatchError;

        this.connect();
        this.init();
    }

    connect() {
        this.ws = new WebSocket(`${this.wsUrl}?sessionId=${this.sessionId}`);
    }

    init() {
        this.ws.onopen = () => {
        };

        this.ws.onclose = () => {
        };

        this.ws.onmessage = event => {
            try {
                const { data, ...rest } = JSON.parse(event.data);
                const message = { ...rest, payload: data, meta: { fromWS: true } };
                this.dispatchMessage(message);
            }
            catch (err) {
                this.dispatchError(err);
            }
        };

        this.ws.onerror = err => {
            this.dispatchError(err);
        };
    }

    close() {
        if (this.ws) {
            this.ws.close();
        }
    }
}

export default new WebSocketTransport(config.server.ws);