export const resolutions = {
    XXS: 240,
    XS: 320,
    S: 480,
    SM: 640,
    M: 768,
    L: 960,
    XL: 1024,
    XXL: 1280
};

export const breakpoints = {
    MOBILE_PORTRAIT: `(max-width: ${resolutions.M}px) and (orientation: portrait)`,
    WIDE: `(min-aspect-ratio : 18/9)`,
    ULTRA_WIDE: `(min-aspect-ratio : 21/9)`,
    PORTRAIT_OR_MEDIUM_LANDSCAPE_STANDARD: `(orientation: portrait), (orientation: landscape) and (max-aspect-ratio: 4/3) and (max-width: ${resolutions.M}px)`
};


