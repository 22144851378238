export default {
    server: {
        http: process.env.REACT_APP_RGS_API_BASE_URL,
        ws: process.env.REACT_APP_RGS_WS_BASE_URL,
        reconnects: {
            delayBetweenAttempts: 3000,
            maxAttempts: 3
        }
    },
    game: {
        shopTestMode: false,
        soundThrottleTime: 300,
        defaultLanguage: 'en',
        isSoundsMutedDefaultValue: true,
        autoCloseDialogTimeout: 5000,
        disconnectWebSocketWhenAppInactive: true,
        animationsDelays: {
            daubing: 600,
            cardsShuffling: 1700
        },
        voiceEnabled: process.env.REACT_APP_VOICE_ENABLED === 'true'
    },
    launcher: {
        operatorKey: '',
        operatorSecret: '',
        redirectUrl: '',
        lobbyUrl: '',
        route: '/v1/bingoLauncher',
        gameId: '',
        userToken: '',
        startTimeInterval: 75000,
        gameConfig: {
            type: 1,
            callFrequencySeconds: 2,
            minPlayers: 1,
            maxPlayers: 100,
            minTicketsPerPlayer: 1,
            maxTicketsPerPlayer: 48,
            mode: 1,
            freeTickets: 24,
            currency_iso: 'USD',
            ticketPrice: 100,
            jackpot: {
                potValue: 25000000,
                trigger: 2,
                threshold: 20
            },
            prizes: {
                "Four Corners": 10000,
                "Big X": 20000,
                "711": 50000,
                "Full House": 32931289
            },
            name: 'bingo game 5'
        }
    }
};