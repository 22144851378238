export default class Channel {
	constructor() {
		this.volume = 1;
		this._muted = false;
		this.sounds = [];
	}
	mute(muted) {
		for (let i = 0, size = this.sounds.length; i < size; i++) {
			this.sounds[i].mute(muted);
		}
		this._muted = muted;
	}
}
