import {
    PLAY_SOUND,
    STOP_SOUND,
    MUTE_SOUNDS,
    UNMUTE_SOUNDS,
    INIT_APP_PARAMS,
    VISIBILITY_CHANGED
} from '../actions/types';

import SoundService from '../../services/soundService';
import { SOUND_LOADED } from '../../services/soundService/events';

export default store => {
    let loadingSoundsIds = {};
    let loadedSounds = new Set();
    let throttledSoundsIds = new Set();

    SoundService.on(SOUND_LOADED, ({ soundId }) => tryPlayLoadedSound(soundId));

    const playSound = (soundId, meta) => {
        const playProps = meta.playProps || {};
        playProps.playOnLoad = meta.playOnLoad;

        if (meta.throttle) {
            if (!throttledSoundsIds.has(soundId)) {
                throttledSoundsIds.add(soundId);
                setTimeout(() => throttledSoundsIds.delete(soundId), meta.throttle.time);
                return SoundService.play(soundId, playProps);
            }
        }
        else {
            return SoundService.play(soundId, playProps);
        }
    };

    const stopSound = (soundId) => {
        SoundService.stop(soundId);
    };

    /*const playSoundQueue = (soundIds, meta) => {
        const playProps = meta.playProps || {};
        playProps.playOnLoad = meta.playOnLoad;

        let index = 0;
        let sound = SoundService.play(soundIds[index], playProps);

        const playNext = (index) => {
            if (soundIds.length > index) {
                let sound = SoundService.play(soundIds[index], playProps);
                sound.on("end", () => { playNext(index + 1); });
            }
        };

        sound.on("end", () => { playNext(index + 1); });
    };*/

    const tryPlayLoadedSound = soundId => {
        loadedSounds.add(soundId);

        if (loadingSoundsIds[soundId] !== undefined) {
            playSound(soundId, loadingSoundsIds[soundId]);
            delete loadingSoundsIds[soundId];
        }
    };

    const tryPlaySound = (soundId, meta) => {
        if (loadedSounds.has(soundId)) {
            playSound(soundId, meta);
        }
        else if (meta.playOnLoad && loadingSoundsIds[soundId] === undefined) {
            loadingSoundsIds[soundId] = meta;
        }
    };

    return next => action => {
        switch (action.type) {
            default:
                break;
            case VISIBILITY_CHANGED:
                if (!store.getState().game.soundsMuted) {
                    SoundService.mute(action.payload);
                }
                break;
            case STOP_SOUND:
                stopSound(action.payload);
                break;
            case PLAY_SOUND:
                tryPlaySound(action.payload, action.meta);
                break;
            case MUTE_SOUNDS:
                SoundService.mute(true);
                break;
            case UNMUTE_SOUNDS:
                SoundService.mute(false);
                break;
            case INIT_APP_PARAMS:
                SoundService.mute(store.getState().game.soundsMuted);
                break;
        }

        next(action);
    }
}

