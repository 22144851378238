import axios from 'axios';
import sha1 from 'js-sha1';
import config from '../config';

const unknownLauncherErrorMessage = 'Launcher error.'

class GameLauncher {
    constructor(httpUrl) {
        this.httpUrl = httpUrl;
    }

    generateSignature(data) {
        let keys = Object.keys(data);
        let vals = [];

        for (let key of keys) {
            let val = data[key];

            if (typeof val !== "object" || val === null) {
                vals.push(val);
            }
        }

        vals.sort();
        return sha1(config.launcher.operatorSecret + vals.join(''));
    };

    async createGame(gameId, mode) {
        const { launcher } = config;

        const data = {
            gameId,
            config: { ...launcher.gameConfig, mode: parseInt(mode) }
        };

        data.config.startTime = new Date(Date.now() + config.launcher.startTimeInterval).toISOString()

        const url = this.httpUrl + '/createLobby';
        const sig = this.generateSignature(data);

        try {
            return await axios({
                method: 'post',
                url: url,
                data: JSON.stringify(data),
                mode: 'cors',
                credentials: 'same-origin',
                headers: {
                    // "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json',
                    'x-tcom-sig': sig,
                    'x-tcom-key': launcher.operatorKey
                }
            });
        }
        catch (err) {
            const message = err.response && err.response.data ? err.response.data : unknownLauncherErrorMessage
            throw new Error(message);
        }
    };

    getGamesList() {
        const { operatorKey, userToken, gameId, redirectUrl, route } = config.launcher;
        const url = `${this.httpUrl}${route}?operator=${operatorKey}&auth_token=${userToken}&game_id=${gameId}&redirect=${redirectUrl}`;
        window.location.replace(url);
    };

    launchGame(gameId, userToken, redirectUrl) {
        const { operatorKey, route } = config.launcher;
        const url = `${this.httpUrl}${route}?operator=${operatorKey}&auth_token=${userToken}&game_id=${gameId}&redirect=${redirectUrl}`;
        window.location.replace(url);
    };

    redirectToLobby() {
        window.location.replace(config.launcher.lobbyUrl);
    };
}


export default new GameLauncher(config.server.http)