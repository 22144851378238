import {
    UPDATE_DATA,
    START_GAME,
    ISSUING_NEXT_NUMBER,
    JACKPOT
} from '../actions/types';

import gameDataReducer from './gameDataReducer';
import config from '../../config';

const initialState = {
    timerToStart: 0,
    user: {
        language: config.game.defaultLanguage
    },
    roundId: -1,
    playersCount: 1
};

export const dataReducer = (state, action) => {
    const { payload } = action;

    switch (action.type) {
        default:
            break;
        case START_GAME:
            return { ...state, nicknamesOfPlayers: payload.lobby.nicknamesOfPlayers };
        case ISSUING_NEXT_NUMBER:
            return { ...state, roundId: payload.roundId - 1 };
        case JACKPOT:
            return { ...state, jackpot: payload };
        case UPDATE_DATA:
            return { ...serverDataReducer(state, payload) };
    }

    return state;
}

export default (state = initialState, action) => (
    { ...dataReducer(state, action), game: gameDataReducer(state.game, action) }
)

const serverDataReducer = (state, { user, game: { roundId, nicknamesOfPlayers } }) => (
    { ...state, user, roundId: roundId || -1, nicknamesOfPlayers }
);