import axios from 'axios';
import config from '../config';
import { CONNECTION_ERROR, NOT_AUTHORIZED } from '../constants/errorCodes';

const NOT_AUTHORIZED_STATUS_CODE = 401;

axios.defaults.headers['Content-Type'] = 'application/json';

const connectionErrorMessage = { id: CONNECTION_ERROR, error: 'Connection error' };
const notAuthorizedErrorMessage = { id: NOT_AUTHORIZED, error: 'Not authorized' };

const getMessage = response => response.status === NOT_AUTHORIZED_STATUS_CODE ? notAuthorizedErrorMessage : response.data;

const handleError = err => {
    const { response } = err;
    const message = response && response.data ? getMessage(response) : connectionErrorMessage;
    throw new HTTPError(message);
};

class HTTPError extends Error {
    constructor(response) {
        super(response);
        this.response = response;
    }
}

class HTTPTransport {
    constructor(httpUrl) {
        this.httpUrl = httpUrl;
    }


    async fetchUserInfo(sessionId) {
        const url = `${this.httpUrl}/user/info?sessionId=${sessionId}`;
        return await axios({
            method: 'get',
            url
        });
    };

    async fetchCurrentGame(sessionId, gameId) {
        const url = `${this.httpUrl}/game/currentGame?sessionId=${sessionId}&gameId=${gameId}`;

        return await axios({
            method: 'get',
            url
        });
    };

    async fetchInitialData(sessionId, gameId) {
        try {
            const result = await Promise.all([this.fetchUserInfo(sessionId), this.fetchCurrentGame(sessionId, gameId)]);
            return { user: result[0].data, game: result[1].data }
        }
        catch (err) {
            handleError(err);
        }
    };

    async purchaseCards(sessionId, gameId, cardsIds) {
        const url = `${this.httpUrl}/game/buyCards?sessionId=${sessionId}&gameId=${gameId}`;

        try {
            return await axios({
                method: 'post',
                url,
                mode: 'cors',
                credentials: 'same-origin',
                data: JSON.stringify({ cardIds: cardsIds })
            });
        }
        catch (err) {
            handleError(err);
        }
    }

    async generateCards(sessionId, gameId, count) {
        const url = `${this.httpUrl}/game/generationNewCards?sessionId=${sessionId}&gameId=${gameId}`;

        try {
            return await axios({
                method: 'post',
                url,
                mode: 'cors',
                credentials: 'same-origin',
                data: JSON.stringify({ count })
            });
        }
        catch (err) {
            handleError(err);
        }
    }
};

export default new HTTPTransport(config.server.http);