
export const array = count => Array.from({ length: count }, (item, index) => index);

export const uid = (() => {
    let id = 0;

    return () => {
        if (id > Number.MAX_SAFE_INTEGER) {
            id = 0;
        }

        return id++;
    }
})();

export const throttle = (fn, interval, context = window) => {
    var wait = false;
    return (...args) => {
        if (!wait) {

            fn.apply(context, args);
            wait = true;

            setTimeout(() => {
                wait = false;
            }, interval);
        }
    }
}

export const debounce = (fn, wait, immediate, context = window) => {
    let timeout;

    return (...args) => {
        const later = () => {
            timeout = null;
            if (!immediate) fn.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) fn.apply(context, args);
    };
};

export const getRootElementFontSize = (() => {
    let rootFontSize;

    return () => {
        if (!rootFontSize) {
            rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        }

        return rootFontSize;
    };
})();

export const remToPx = value => value * getRootElementFontSize();

const addLeadingZero = part => part < 10 ? `0${part}` : part;

export const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    return `${addLeadingZero(hours % 60)}:${addLeadingZero(minutes % 60)}:${addLeadingZero(seconds % 60)}`;
}

//polyfill for IE/Edge
if (!Array.prototype.flat) {
    /* eslint-disable no-extend-native */
    Array.prototype.flat = function() {
        var depth = isNaN(arguments[0]) ? 1 : Number(arguments[0]);

        return depth ? Array.prototype.reduce.call(this, function(acc, cur) {
            if (Array.isArray(cur)) {
                acc.push.apply(acc, Array.prototype.flat.call(cur, depth - 1));
            } else {
                acc.push(cur);
            }

            return acc;
        }, []) : Array.prototype.slice.call(this);
    }
    /* eslint-enable no-extend-native */
}




