import CrossWindowTransport from '../../transports/CrossWindowTransport';
import { CLOSE_GAME, RELOAD } from '../actions/types';

export default store => {
    return next => action => {
        switch (action.type) {
            default:
                break;
            case CLOSE_GAME:
                CrossWindowTransport.close();
                break;
            case RELOAD:
                window.location.reload();
                break;
        }

        next(action);
    };
};