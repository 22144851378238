import config from "../../config";
import bgMusic from "./res/music.mp3";
import buttonClickSound from "./res/button_click.mp3";
import buyTicketsSound from "./res/buy_tickets.mp3";
import welcomeSound from "./res/game_start.mp3";
import notificationSound from "./res/notification.mp3";
import numberCallSound from "./res/number_call.mp3";
import numberMatchSound from "./res/number_match.mp3";
import winDialogSound from "./res/win_dialog.mp3";
import bingoCallSound from "./res/BingoCalls.mp3";

import SC from "../../constants/sounds";

const MUSIC_CHANNEL = "music";
const SOUND_CHANNEL = "sfx";

const bingoCalls = {
  name: "BingoCalls",
  url: bingoCallSound,
  channel: SOUND_CHANNEL,
  sprite: {
    BestOfLuckEveryone: [0, 3030.204081632653],
    BestOfLuckNextTime: [5000, 3134.6938775510207],
    CongratsToAllWinners: [10000, 4202.811791383219],
    Jackpot: [16000, 2507.755102040818],
    Number_01: [20000, 2380.0453514739247],
    Number_02: [24000, 2229.1156462585027],
    Number_03: [28000, 2310.385487528343],
    Number_04: [32000, 2345.2154195011303],
    Number_05: [36000, 2670.2947845804983],
    Number_06: [40000, 2461.315192743761],
    Number_07: [44000, 2391.655328798187],
    Number_08: [48000, 2577.4149659863924],
    Number_09: [52000, 2298.775510204081],
    Number_10: [56000, 2577.4149659863924],
    Number_11: [60000, 2020.1360544217691],
    Number_12: [64000, 2229.1156462585063],
    Number_13: [68000, 2867.6643990929733],
    Number_14: [72000, 3111.473922902491],
    Number_15: [77000, 2716.7346938775554],
    Number_16: [81000, 2699.319727891151],
    Number_17: [85000, 2287.1655328798115],
    Number_18: [89000, 2438.0952380952435],
    Number_19: [93000, 2252.335600907031],
    Number_20: [97000, 1973.6961451247198],
    Number_21: [100000, 2449.705215419499],
    Number_22: [104000, 2258.140589569166],
    Number_23: [108000, 2261.0430839002333],
    Number_24: [112000, 2484.5351473922933],
    Number_25: [116000, 2681.904761904761],
    Number_26: [120000, 2705.124716553286],
    Number_27: [124000, 2446.8027210884316],
    Number_28: [128000, 2684.807256235814],
    Number_29: [132000, 2693.5147392290164],
    Number_30: [136000, 1811.1564625850463],
    Number_31: [139000, 3091.1564625850474],
    Number_32: [144000, 2414.8752834467186],
    Number_33: [148000, 2403.265306122449],
    Number_34: [152000, 2461.3151927437684],
    Number_35: [156000, 2803.8095238095193],
    Number_36: [160000, 2507.755102040818],
    Number_37: [164000, 2693.5147392290164],
    Number_38: [168000, 2414.8752834467186],
    Number_39: [172000, 2739.9546485260657],
    Number_40: [176000, 2919.909297052158],
    Number_41: [180000, 2414.8752834467186],
    Number_42: [184000, 2252.335600907031],
    Number_43: [188000, 2536.7800453514633],
    Number_44: [192000, 2728.344671201825],
    Number_45: [196000, 2467.120181405903],
    Number_46: [200000, 2786.394557823115],
    Number_47: [204000, 2507.755102040818],
    Number_48: [208000, 2972.154195011342],
    Number_49: [212000, 3343.673469387767],
    Number_50: [217000, 2240.7256235827617],
    Number_51: [221000, 2426.485260770988],
    Number_52: [225000, 2368.4353741496693],
    Number_53: [229000, 2693.5147392290164],
    Number_54: [233000, 2856.054421768704],
    Number_55: [237000, 3346.575963718834],
    Number_56: [242000, 2856.054421768704],
    Number_57: [246000, 2693.5147392290164],
    Number_58: [250000, 2229.1156462584922],
    Number_59: [254000, 2229.1156462584922],
    Number_60: [258000, 2461.3151927437684],
    Number_61: [262000, 2414.8752834466904],
    Number_62: [266000, 2832.834467120165],
    Number_63: [270000, 2809.6145124716827],
    Number_64: [274000, 2798.004535147413],
    Number_65: [278000, 2647.0748299319666],
    Number_66: [282000, 2507.7551020407896],
    Number_67: [286000, 2438.0952380952294],
    Number_68: [290000, 2986.666666666679],
    Number_69: [294000, 2812.5170068026932],
    Number_70: [298000, 2182.675736961471],
    Number_71: [302000, 2739.9546485260657],
    Number_72: [306000, 2876.3718820861754],
    Number_73: [310000, 2745.7596371882005],
    Number_74: [314000, 2763.1746031746047],
    Number_75: [318000, 2705.124716553314],
    Number_76: [322000, 2554.1950113378675],
    Number_77: [326000, 2716.7346938775268],
    Number_78: [330000, 2658.684807256236],
    Number_79: [334000, 2362.6303854875346],
    Number_80: [338000, 1851.791383219961],
    Number_81: [341000, 2472.925170068038],
    Number_82: [345000, 2577.4149659864065],
    Number_83: [349000, 2693.5147392290446],
    Number_84: [353000, 2554.1950113378675],
    Number_85: [357000, 2490.340136054442],
    Number_86: [361000, 2670.2947845805056],
    Number_87: [365000, 2263.9455782313007],
    Number_88: [369000, 2577.4149659864065],
    Number_89: [373000, 2391.6553287982083],
    Number_90: [377000, 2577.4149659864065],
    WeHaveAWinner: [381000, 2530.9750566893285],
  },
};

const sounds = [
  {
    name: SC.BG_MUSIC,
    url: bgMusic,
    channel: MUSIC_CHANNEL,
  },
  {
    name: SC.BUTTON_CLICK,
    url: buttonClickSound,
    channel: SOUND_CHANNEL,
  },
  {
    name: SC.BUY_TICKETS,
    url: buyTicketsSound,
    channel: SOUND_CHANNEL,
  },
  {
    name: SC.WELCOME,
    url: welcomeSound,
    channel: SOUND_CHANNEL,
  },
  {
    name: SC.NOTIFICATION,
    url: notificationSound,
    channel: SOUND_CHANNEL,
  },
  {
    name: SC.NUMBER_CALL,
    url: numberCallSound,
    channel: SOUND_CHANNEL,
  },
  {
    name: SC.NUMBER_MATCH,
    url: numberMatchSound,
    channel: SOUND_CHANNEL,
  },
  {
    name: SC.WIN_DIALOG,
    url: winDialogSound,
    channel: SOUND_CHANNEL,
  },
];

if (config.game.voiceEnabled) sounds.push(bingoCalls);

export default sounds;
