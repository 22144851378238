
import WebSocketTransport from '../../transports/WebSocketTransport';

import config from '../../config';
import {
    updateData,
    errorNotification,
    webSocketError,
    generateCards,
    showLoader,
    hideLoader,
    playBuyTicketsSound,
    playNumberCallSound,
    showPurchaseDialog
} from '../actions';

import {
    FETCH_INITIAL_DATA_SUCCESS,
    PURCHASE_CARDS_SUCCESS,
    PURCHASE_CARDS_FAILURE,
    TRY_PURCHASE_CARDS,
    PURCHASE_CARDS,
    ISSUING_NEXT_NUMBER,
    TRIGGER_PAUSED_ACTIONS,
    VISIBILITY_CHANGED
} from '../actions/types';

import EC from '../../constants/errorCodes';

export default store => {
    let WSMessagesQueue = {};

    const processActions = action => {
        switch (action.type) {
            default:
                break;
            case FETCH_INITIAL_DATA_SUCCESS:
                store.dispatch(updateData(action.payload));
                tryGenerateCards();
                setupWebSocket();
                break;
            case TRY_PURCHASE_CARDS:
                handleCardsPurchasingTry();
                break;
            case PURCHASE_CARDS:
                store.dispatch(showLoader());
                break;
            case PURCHASE_CARDS_FAILURE:
                store.dispatch(hideLoader());
                break;
            case PURCHASE_CARDS_SUCCESS:
                store.dispatch(hideLoader());
                store.dispatch(playBuyTicketsSound());
                break;
            case ISSUING_NEXT_NUMBER:
                store.dispatch(playNumberCallSound());
                console.log('Playing number call sound', action.payload)
                break;
            case TRIGGER_PAUSED_ACTIONS:
                handlePausedMessageTriggering();
                break;
            case VISIBILITY_CHANGED:
                handleTabVisibilityChange(action.payload);
                break;
        }
    }

    const handlePausedMessageTriggering = () => {
        const WSMessageToTrigger = { ...WSMessagesQueue };
        WSMessagesQueue = {};

        Object.keys(WSMessageToTrigger).forEach(type => {
            if (WSMessageToTrigger[type]) {
                store.dispatch(WSMessageToTrigger[type]);
            }
        });
    }

    const tryGenerateCards = () => {
        const { cards, config } = store.getState().data.game;
        const notPurchasedCardsAmount = config.maxTicketsPerPlayer - cards.length;

        if (notPurchasedCardsAmount > 0) {
            store.dispatch(generateCards(notPurchasedCardsAmount));
        }
    }

    const handleCardsPurchasingTry = () => {
        const { data, game } = store.getState();
        const { balance } = data.user;
        const { ticketPrice } = data.game.config;
        const { selectedCardsIds } = game;
        const canPurchase = selectedCardsIds.length * ticketPrice <= balance || config.game.shopTestMode;
        if (canPurchase) {
            store.dispatch(showPurchaseDialog())
        }
        else {
            store.dispatch(errorNotification(EC.NOT_ENOUGH_BALANCE));
        }
    }

    const handleTabVisibilityChange = hidden => {
        if (config.game.disconnectWebSocketWhenAppInactive) {
            if (hidden) {
                closeWebSocket();
            } else {
                setupWebSocket();
            }
        }
    }

    const onWebSocketError = err => {
        store.dispatch(webSocketError(err));
    };

    const setupWebSocket = () => {
        WebSocketTransport.setup(store.getState().app.sessionId, store.dispatch, onWebSocketError);
    };

    const closeWebSocket = () => {
        WebSocketTransport.close();
    };

    const isWebSocketMessage = ({ meta }) => meta && meta.fromWS;
    const isAllowedWebSocketMessage = ({ lobbyId }) => store.getState().app.gameId === lobbyId;

    return next => action => {
        processActions(action);
        if (isWebSocketMessage(action)) {
            if (isAllowedWebSocketMessage(action)) {
                const { isPaused } = store.getState().app;
                console.log('WS DATA', action);

                if (isPaused) {
                    WSMessagesQueue[action.type] = action;
                }
                else {
                    next(action);
                }
            }
        }
        else {
            next(action);
        }
    }
}
