import { createAction } from 'redux-actions';

import launcherMock from '../../mocks/launcherMock';
import {
    CREATE_GAME,
    CREATE_GAME_SUCCESS,
    CREATE_GAME_FAILURE,
    LAUNCH_GAME,
    GO_TO_LOBBY
} from './types';

export const createGame = (gameId, mode) => async dispatch => {
    dispatch({
        type: CREATE_GAME
    });

    try {
        const result = await launcherMock.createGame(gameId, mode);
        createGameSuccess(dispatch, result);
    }
    catch (err) {
        createGameFailure(dispatch);
    }
};

export const createGameSuccess = createAction(CREATE_GAME_SUCCESS);
export const createGameFailure = createAction(CREATE_GAME_FAILURE);


export const launchGame = (gameId, userToken, redirectUrl) => async dispatch => {
    dispatch({
        type: LAUNCH_GAME
    });

    launcherMock.launchGame(gameId, userToken, redirectUrl);
};

export const goToLobby = () => async dispatch => {
    dispatch({
        type: GO_TO_LOBBY
    });

    launcherMock.redirectToLobby();
};
