import React, { PureComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { debounce } from '../utils';

const BASE_SIZE = { width: 1920, height: 1080 };
const DEBOUNCE_INTERVAL = 250;
const PORTRAIT_REF_WIDTH = 527;

export default class WindowDimensionsProvider extends PureComponent {
    constructor(props) {
        super(props);
        this.state = this.getWindowProps();
        this.resizeHandler = debounce(this.resizeHandler.bind(this), DEBOUNCE_INTERVAL);
        this.orientationHandler = this.orientationHandler.bind(this);
    }

    getWindowProps() {
        const { clientWidth: width, clientHeight: height } = document.documentElement;
        const orientation = width >= height ? 'landscape' : 'portrait';
        let targetScale = width / BASE_SIZE.width;

        targetScale = orientation === 'landscape' ? (targetScale > 1 ? 1 : targetScale) : width / PORTRAIT_REF_WIDTH;

        return {
            width,
            height,
            scale: targetScale,
            orientation
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.resizeHandler);
        window.addEventListener('orientationchange', this.orientationHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeHandler);
        window.removeEventListener('orientationchange', this.orientationHandler);
    }

    resizeHandler() {
        this.setState({ ...this.state, ...this.getWindowProps() });
    }

    orientationHandler() {
    }

    render() {
        return (
            <ThemeProvider theme={{ ...this.state }}>
                {this.props.children}
            </ThemeProvider>
        )
    }
}








