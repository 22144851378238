import { createAction } from 'redux-actions';

import {
    INIT_GAME_TIMER,
    GAME_TIMER_TICK,
    CLOSE_DIALOG,
    UPDATE_DIALOGS_QUEUE,
    SHOP_STATE_CHANGE,
    TRY_PURCHASE_CARDS,
    SHOW_LOADER,
    HIDE_LOADER,
    MUTE_SOUNDS,
    UNMUTE_SOUNDS,
    SELECT_CARD_WITH_ID,
    DESELECT_CARD_WITH_ID,
    SELECT_CARDS,
    SHOW_PURCHASE_DIALOG,
    AUTO_CLOSE_DIALOG_WITH_TIMEOUT,
    SHOW_MAGNIFIED_BEST_CARD,
    HIDE_MAGNIFIED_BEST_CARD,
    REVEAL_FREE_MODE_CARDS
} from './types';

export const initGameTimer = createAction(INIT_GAME_TIMER,
    (timerIntervalId, secondsToStartGame) => ({ timerIntervalId, secondsToStartGame }));

export const gameTimerTick = createAction(GAME_TIMER_TICK);
export const closeDialog = createAction(CLOSE_DIALOG);
export const updateDialogsQueue = createAction(UPDATE_DIALOGS_QUEUE);
export const shopStateChange = createAction(SHOP_STATE_CHANGE);
export const tryPurchaseCards = createAction(TRY_PURCHASE_CARDS);
export const showLoader = createAction(SHOW_LOADER);
export const hideLoader = createAction(HIDE_LOADER);
export const muteSounds = createAction(MUTE_SOUNDS);
export const unmuteSounds = createAction(UNMUTE_SOUNDS);
export const selectCardWithId = createAction(SELECT_CARD_WITH_ID);
export const deselectCardWithId = createAction(DESELECT_CARD_WITH_ID);
export const selectCards = createAction(SELECT_CARDS);
export const showPurchaseDialog = createAction(SHOW_PURCHASE_DIALOG);
export const autoCloseDialogWithTimeout = createAction(AUTO_CLOSE_DIALOG_WITH_TIMEOUT);
export const showMagnifiedBestCard = createAction(SHOW_MAGNIFIED_BEST_CARD);
export const hideMagnifiedBestCard = createAction(HIDE_MAGNIFIED_BEST_CARD);
export const revealFreeModeCards = createAction(REVEAL_FREE_MODE_CARDS);