import React from 'react';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux';
import HTTPRequestsMiddleware from './store/middlewares/HTTPRequestsMiddleware';
import serverActionsMiddleware from './store/middlewares/serverActionsMiddleware';
import dialogsQueueMiddleware from './store/middlewares/dialogsQueueMiddleware';
import gameTimerMiddleware from './store/middlewares/gameTimerMiddleware';
import gameEventsMiddleware from './store/middlewares/gameEventsMiddleware';
import logErrorsMiddleware from './store/middlewares/logErrorsMiddleware';
import soundMiddleware from './store/middlewares/soundMiddleware';
import perfomanceMiddleware from './store/middlewares/perfomanceMiddleware';
import rootReducer from './store/reducers';
import LaunchScreen from './screens/LaunchScreen';
import WindowDimensionsProvider from './contexts/WindowDimensionsProvider';

const composeEnhancers = process.env.NODE_ENV === 'development' ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
const middlewaresChain = [thunk, HTTPRequestsMiddleware, serverActionsMiddleware, dialogsQueueMiddleware,
  gameTimerMiddleware, gameEventsMiddleware, soundMiddleware, logErrorsMiddleware];
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(...(process.env.NODE_ENV === 'development' ? [perfomanceMiddleware, ...middlewaresChain] : middlewaresChain))));

const App = props => {
  return (
    <Provider store={store}>
      <WindowDimensionsProvider>
        <LaunchScreen />
      </WindowDimensionsProvider>
    </Provider>
  );
}

export default App;
