import { createAction } from 'redux-actions';

import {
    FETCH_INITIAL_DATA,
    FETCH_INITIAL_DATA_SUCCESS,
    FETCH_INITIAL_DATA_FAILURE,
    PURCHASE_CARDS,
    PURCHASE_CARDS_SUCCESS,
    PURCHASE_CARDS_FAILURE,
    GENERATE_CARDS,
    GENERATE_CARDS_SUCCESS,
    GENERATE_CARDS_FAILURE,
    UPDATE_DATA,
    UPDATE_GAME_STATE

} from './types';

import { HTTP_REQUEST } from '../../constants/specialActionsMetaTypes';

export const fetchInitialData = (...args) => {
    return {
        type: FETCH_INITIAL_DATA,
        payload: args,
        meta: {
            type: HTTP_REQUEST,
            handleActionsTypes: [FETCH_INITIAL_DATA_SUCCESS, FETCH_INITIAL_DATA_FAILURE],
            initial: true
        }
    }
};

export const purchaseCards = (...args) => {
    return {
        type: PURCHASE_CARDS,
        payload: args,
        meta: {
            type: HTTP_REQUEST,
            handleActionsTypes: [PURCHASE_CARDS_SUCCESS, PURCHASE_CARDS_FAILURE]
        }
    }
};

export const generateCards = (...args) => {
    return {
        type: GENERATE_CARDS,
        payload: args,
        meta: {
            type: HTTP_REQUEST,
            handleActionsTypes: [GENERATE_CARDS_SUCCESS, GENERATE_CARDS_FAILURE]
        }
    }
};


export const updateData = createAction(UPDATE_DATA);
export const updateGameState = createAction(UPDATE_GAME_STATE);