import {
    UPDATE_DATA,
    START_GAME,
    END_GAME,
    CANCEL,
    GENERATE_CARDS_SUCCESS,
    PURCHASE_CARDS_SUCCESS,
    PLAYER_JOINED,
    WIN_PATTERN
} from '../actions/types';

import {
    LOADING,
    PLAYING,
    FINISHED,
    CANCELLED
} from '../../constants/gameStates';

const initialState = {
    startTime: 0,
    serverTime: 0,
    status: LOADING,
    sequence: [],
    cards: [],
    players: [],
    gameType: null,
    config: {},
    playersCount: 1
};

export default (state = initialState, action) => {
    const { payload } = action;

    switch (action.type) {
        default:
            break;
        case START_GAME:
            return { ...state, ...payload.lobby, playersCount: payload.lobby.players.length, status: PLAYING };
        case END_GAME:
            return { ...state, ...payload.lobby, status: FINISHED };
        case CANCEL:
            return { ...state, status: CANCELLED };
        case UPDATE_DATA:
            return { ...state, ...payload.game, playersCount: payload.game.players.length, ...cardsAndSequenceReducer(state, payload) };
        case GENERATE_CARDS_SUCCESS:
            return { ...state, ...payload.game, cards: generatedCardsReducer(state, payload) };
        case PURCHASE_CARDS_SUCCESS:
            return { ...state, ...payload.game, cards: purchasedCardsReducer(state, payload) };
        case PLAYER_JOINED:
            return { ...state, playersCount: payload.playersCount };
        case WIN_PATTERN:
            return { ...state, usedPatterns: [...new Set([...state.usedPatterns, payload.patternName])] };
    }

    return state;
}

const cardsAndSequenceReducer = (state, payload) => {
    const { game } = payload;
    const { sequence, cards } = game;
    const updatedCards = cards.map(item => !item.purchased ? { ...item, purchased: false } : item);

    return { cards: updatedCards, sequence: sequence || [] };
};

const generatedCardsReducer = (state, payload) => {
    const { cards } = state;
    const generatedCards = payload.data.data.map(item => ({ ...item, isFreeCard: false, purchased: false }))
    return [...cards, ...generatedCards];
}

const purchasedCardsReducer = (state, payload) => {
    const { cards } = state;
    const purchasedCardsIds = payload.data.data.purchasedCards;
    return cards.map(item => purchasedCardsIds.includes(item.id) ? { ...item, purchased: true } : item);
}


