import {
    INIT_APP_PARAMS,
    INCREMENT_RECONNECT_ATTEMPS,
    RESET_RECONNECT_ATTEMPS,
    PAUSE,
    UNPAUSE,
} from '../actions/types';

const initialState = {
    sessionId: null,
    gameId: null,
    reconnectAttempts: 0,
    reconnectTimeoutId: null,
    isPaused: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case INIT_APP_PARAMS:
            return { ...state, ...action.payload };
        case INCREMENT_RECONNECT_ATTEMPS:
            return { ...state, reconnectAttempts: state.reconnectAttempts + 1, reconnectTimeOutId: action.payload };
        case RESET_RECONNECT_ATTEMPS:
            return { ...state, reconnectAttempts: 0, reconnectTimeOutId: null };
        case PAUSE:
            return { ...state, isPaused: true };
        case UNPAUSE:
            return { ...state, isPaused: false };
        default:
            return state;
    }
}

