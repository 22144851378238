import { createAction } from 'redux-actions';

import {
    INIT_APP_PARAMS,
    INCREMENT_RECONNECT_ATTEMPS,
    RESET_RECONNECT_ATTEMPS,
    ERROR_NOTIFICATION,
    WEB_SOCKET_ERROR,
    CLOSE_GAME,
    RELOAD,
    VISIBILITY_CHANGED,
    PAUSE,
    UNPAUSE,
    TRIGGER_PAUSED_ACTIONS
} from './types';

export const initAppParams = createAction(INIT_APP_PARAMS);
export const incrementReconnectAttempts = createAction(INCREMENT_RECONNECT_ATTEMPS);
export const resetReconnectAttempts = createAction(RESET_RECONNECT_ATTEMPS);
export const errorNotification = createAction(ERROR_NOTIFICATION);
export const closeGame = createAction(CLOSE_GAME);
export const reload = createAction(RELOAD);
export const visibilityChanged = createAction(VISIBILITY_CHANGED);
export const pause = createAction(PAUSE);
export const unpause = createAction(UNPAUSE);
export const triggerPausedActions = createAction(TRIGGER_PAUSED_ACTIONS);

export const webSocketError = error => {
    return {
        type: WEB_SOCKET_ERROR,
        payload: error,
        error: true
    }
}
