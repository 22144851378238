
export const TRY_PURCHASE_CARDS = 'TRY_PURCHASE_CARDS';
export const PURCHASE_CARDS = 'PURCHASE_CARDS';
export const PURCHASE_CARDS_SUCCESS = 'PURCHASE_CARDS_SUCCESS';
export const PURCHASE_CARDS_FAILURE = 'PURCHASE_CARDS_FAILURE';
export const GENERATE_CARDS = 'GENERATE_CARDS';
export const GENERATE_CARDS_SUCCESS = 'GENERATE_CARDS_SUCCESS';
export const GENERATE_CARDS_FAILURE = 'GENERATE_CARDS_FAILURE';
export const CALL_NUMBER = 'CALL_NUMBER';

export const CREATE_GAME = 'CREATE_GAME';
export const CREATE_GAME_SUCCESS = 'CREATE_GAME_SUCCESS';
export const CREATE_GAME_FAILURE = 'CREATE_GAME_FAILURE';
export const LAUNCH_GAME = 'LAUNCH_GAME';
export const FETCH_INITIAL_DATA = 'FETCH_INITIAL_DATA';
export const FETCH_INITIAL_DATA_FAILURE = 'FETCH_INITIAL_DATA_FAILURE';
export const FETCH_INITIAL_DATA_SUCCESS = 'FETCH_INITIAL_DATA_SUCCESS';
export const UPDATE_DATA = 'UPDATE_DATA';
export const INIT_GAME_TIMER = 'INIT_GAME_TIMER';
export const GAME_TIMER_TICK = 'GAME_TIMER_TICK';
export const START_GAME = 'START_GAME';
export const END_GAME = 'END_GAME';
export const UPDATE_GAME_STATE = 'UPDATE_GAME_STATE';
export const ISSUING_NEXT_NUMBER = 'ISSUING_NEXT_NUMBER';
export const JACKPOT = 'JACKPOT';
export const WIN_PATTERN = 'WIN_PATTERN';
export const CANCEL = 'CANCEL';
export const PLAYER_JOINED = 'PLAYER_JOINED';

export const ERROR_NOTIFICATION = 'ERROR_NOTIFICATION';
export const INIT_APP_PARAMS = 'INIT_APP_PARAMS';
export const INCREMENT_RECONNECT_ATTEMPS = 'INCREMENT_RECONNECT_ATTEMPS';
export const RESET_RECONNECT_ATTEMPS = 'RESET_RECONNECT_ATTEMPS';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const UPDATE_DIALOGS_QUEUE = 'UPDATE_DIALOGS_QUEUE';
export const GO_TO_LOBBY = 'GO_TO_LOBBY';
export const WEB_SOCKET_ERROR = 'WEB_SOCKET_ERROR';
export const CLOSE_GAME = 'CLOSE_GAME';
export const SHOP_STATE_CHANGE = 'SHOP_STATE_CHANGE';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const RELOAD = 'RELOAD';
export const VISIBILITY_CHANGED = 'VISIBILITY_CHANGED';

export const PLAY_SOUND = 'PLAY_SOUND';
export const STOP_SOUND = 'STOP_SOUND';
export const MUTE_SOUNDS = 'MUTE_SOUNDS';
export const UNMUTE_SOUNDS = 'UNMUTE_SOUNDS';
export const PAUSE = 'PAUSE';
export const UNPAUSE = 'UNPAUSE';
export const SELECT_CARD_WITH_ID = 'SELECT_CARD_WITH_ID';
export const DESELECT_CARD_WITH_ID = 'DESELECT_CARD_WITH_ID';
export const SELECT_CARDS = 'SELECT_CARDS';
export const DESELECT_CARDS = 'DESELECT_CARDS';
export const SHOW_PURCHASE_DIALOG = 'SHOW_PURCHASE_DIALOG';
export const TRIGGER_PAUSED_ACTIONS = 'TRIGGER_PAUSED_ACTIONS';
export const AUTO_CLOSE_DIALOG_WITH_TIMEOUT = 'AUTO_CLOSE_DIALOG_WITH_TIMEOUT';

export const SHOW_MAGNIFIED_BEST_CARD = 'SHOW_MAGNIFIED_BEST_CARD';
export const HIDE_MAGNIFIED_BEST_CARD = 'HIDE_MAGNIFIED_BEST_CARD';

export const REVEAL_FREE_MODE_CARDS = 'REVEAL_FREE_MODE_CARDS';