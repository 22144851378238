import {
    INIT_GAME_TIMER,
    GAME_TIMER_TICK,
    CLOSE_DIALOG,
    UPDATE_DIALOGS_QUEUE,
    SHOP_STATE_CHANGE,
    SHOW_LOADER,
    HIDE_LOADER,
    ISSUING_NEXT_NUMBER,
    MUTE_SOUNDS,
    UNMUTE_SOUNDS,
    SELECT_CARD_WITH_ID,
    DESELECT_CARD_WITH_ID,
    SELECT_CARDS,
    AUTO_CLOSE_DIALOG_WITH_TIMEOUT,
    SHOW_MAGNIFIED_BEST_CARD,
    HIDE_MAGNIFIED_BEST_CARD,
    REVEAL_FREE_MODE_CARDS
} from '../actions/types';

import config from '../../config';

const initialState = {
    dialogsQueue: [],
    isShopOpened: false,
    showLoader: false,
    currentCallNumberMatchSoundPlayed: false,
    soundsMuted: config.game.isSoundsMutedDefaultValue,
    selectedCardsIds: [],
    isMagnifiedCardFeatureOn: false,
    freeModeCardsRevealed: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        default:
            break;
        case INIT_GAME_TIMER:
            return { ...state, ...action.payload };
        case GAME_TIMER_TICK:
            return { ...state, secondsToStartGame: Math.max(state.secondsToStartGame - 1, 0) };
        case UPDATE_DIALOGS_QUEUE:
            return { ...state, dialogsQueue: [...state.dialogsQueue, action.payload] };
        case CLOSE_DIALOG:
            return { ...state, dialogsQueue: state.dialogsQueue.filter(item => item.id !== action.payload) };
        case SHOP_STATE_CHANGE:
            return { ...state, isShopOpened: action.payload };
        case SHOW_LOADER:
            return { ...state, showLoader: true };
        case HIDE_LOADER:
            return { ...state, showLoader: false };
        case ISSUING_NEXT_NUMBER:
            return { ...state, currentCallNumberMatchSoundPlayed: false };
        case MUTE_SOUNDS:
            return { ...state, soundsMuted: true };
        case UNMUTE_SOUNDS:
            return { ...state, soundsMuted: false };
        case SELECT_CARD_WITH_ID:
            return { ...state, selectedCardsIds: [...state.selectedCardsIds, action.payload] };
        case DESELECT_CARD_WITH_ID:
            return { ...state, selectedCardsIds: state.selectedCardsIds.filter(item => item !== action.payload) };
        case SELECT_CARDS:
            return { ...state, selectedCardsIds: action.payload };
        case AUTO_CLOSE_DIALOG_WITH_TIMEOUT:
            const { autoCloseDialogTimeoutId } = action.payload;
            return { ...state, autoCloseDialogTimeoutId };
        case SHOW_MAGNIFIED_BEST_CARD:
            return { ...state, isMagnifiedCardFeatureOn: true };
        case HIDE_MAGNIFIED_BEST_CARD:
            return { ...state, isMagnifiedCardFeatureOn: false };
        case REVEAL_FREE_MODE_CARDS:
            return { ...state, freeModeCardsRevealed: true };
    }
    return state;
}


