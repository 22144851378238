import styled from 'styled-components';

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        width: 32vmin;
    }
`;

export const PreloadBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, #0c0c1e 0%, #181637 50%, #0a0c1a 100%);
`;

export const LoadingBarBackground = styled.div`
  background-color: #000;
  border-radius: 2px;
  width: 35vmin;
  height: 1vmin;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  margin-top: 3vmin;
`;

export const LoadingBar = styled.div`
  background-color: #FFF;
  height: 100%;
  transition: width 0.7s;
  width: ${props => 100 * Math.min(props.progress, 1)}%;
`;

export const Window = styled.div`
    width: 100vw;
    height: 100vh;
`;
