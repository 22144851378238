import {
    FETCH_INITIAL_DATA_SUCCESS,
    START_GAME,
    AUTO_CLOSE_DIALOG_WITH_TIMEOUT,
    CLOSE_DIALOG
} from '../actions/types';

import { initGameTimer, gameTimerTick, closeDialog } from '../actions';
import config from '../../config';

const timerInterval = 1000;

export default store => {

    const runTimer = (startTime, serverTime) => {
        stopTimer();
        const secondsToStart = Math.floor((startTime - serverTime) / 1000);

        const timerIntervalId = setInterval(() => {
            store.dispatch(gameTimerTick());
        }, timerInterval);

        store.dispatch(initGameTimer(timerIntervalId, secondsToStart));
    }

    const stopTimer = () => {
        clearInterval(store.getState().game.timerIntervalId);
    }

    const runAutoCloseDialogTimer = dialogId => {
        return setTimeout(() => {
            store.dispatch(closeDialog(dialogId));
        }, config.game.autoCloseDialogTimeout)
    };

    const stopAutoCloseDialogTimer = autoCloseDialogTimeoutId => clearTimeout(autoCloseDialogTimeoutId);

    return next => action => {
        switch (action.type) {
            default:
                break;
            case FETCH_INITIAL_DATA_SUCCESS:
                const { data: { game: { startTime, serverTime } } } = store.getState();

                if (startTime > serverTime) {
                    runTimer(startTime, serverTime);
                }
                break;
            case START_GAME:
                stopTimer();
                break;
            case AUTO_CLOSE_DIALOG_WITH_TIMEOUT:
                const autoCloseDialogTimeoutId = runAutoCloseDialogTimer(action.payload.dialogId);
                action.payload = { ...action.payload, autoCloseDialogTimeoutId };
                break;
            case CLOSE_DIALOG:
                stopAutoCloseDialogTimer(store.getState().game.autoCloseDialogTimeoutId);
                break;
        }

        next(action);
    }
}

