import React, { PureComponent, Suspense, lazy } from 'react';
import styled from 'styled-components';

import qs from 'query-string';
import { connect } from 'react-redux';
import { createGame, launchGame, initAppParams, fetchInitialData, unmuteSounds, muteSounds, playButtonClickSound } from '../store/actions';
import Preloader from '../components/common/Preloader';
import config from '../config';

const GameScreen = lazy(() => import('./GameScreen'));

const Window = styled.div`
    width: 100vw;
    height: 100vh;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(27, 25, 94, 0.5);
    border: 0.16rem solid white;
    border-radius: 1rem;
    margin-right: 0.5rem;
    color: white;
    font-size: 1rem;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
`;

const Button = styled.button`
  color: LightYellow;
  font-size: 1em;
  margin: 1rem;
  padding: 0.25em 1em;
  border: 2px solid LightYellow;
  border-radius: 3px;
  background-color: navy;
  cursor: pointer;
  width: 10rem;
  height: 3rem;
  text-transform: uppercase;
  font-weight: bold;
`;

const FormRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 22rem;
    flex-direction: row;
    border: 0.0625rem solid white;
    padding: 1rem;

    input {
        font-size: 0.8rem;
        width: 12rem;
    }
`;

const PROGRESS_TIMEOUT = 300;
const PROGRESS_STEP = 0.08;


//Mock screen to emulate game launch
class LaunchScreen extends PureComponent {
    state = { config: { gameId: '', userToken: '', redirectUrl: '', mode: '' }, progressValue: 0.1, loadingComplete: false }

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    initAppDataByGetParams() {
        const params = qs.parse(window.location.search);
        const { sessionId, gameId } = params;

        if (sessionId && gameId) {
            this.props.initAppParams({ sessionId, gameId });
            this.props.fetchInitialData();
        }

        window.addEventListener('message', e => this.receiveMessage(e));

        if (window.parent)
            window.parent.postMessage('READY', '*');
    }

    receiveMessage(event) {
      switch (event.data) {
        default:
          break;

        case 'ENABLE_SOUND':
          this.props.unmuteSounds();
          this.props.playButtonClickSound();
          console.log('inGame enabled sound');
          break;

        case 'DISABLE_SOUND':
          this.props.muteSounds();
          this.props.playButtonClickSound();
          console.log('inGame disabled sound');
          break;
      }
    }

    componentDidMount() {
        const { gameId, userToken, redirectUrl, gameConfig } = config.launcher;
        const { http, ws } = config.server;

        this.setState({
            ...this.state, config: {
                gameId,
                userToken,
                redirectUrl,
                http,
                ws,
                mode: gameConfig.mode
            }
        });

        this.initAppDataByGetParams();

        this.progressTimeout = setTimeout(() => {
            this.setState({ ...this.state, progressValue: this.state.progressValue + 0.1 })
        }, PROGRESS_TIMEOUT)
    }

    componentWillUnmount() {
        clearTimeout(this.progressTimeout);
        clearTimeout(this.loadingCompleteTimeout);
        window.removeEventListener('message', e => this.receiveMessage(e));
    }

    componentDidUpdate(prevProps, prevState) {
        clearTimeout(this.progressTimeout);

        if (prevState.progressValue < 1) {
            this.progressTimeout = setTimeout(() => {
                this.setState({ ...this.state, progressValue: this.state.progressValue + PROGRESS_STEP })
            }, PROGRESS_TIMEOUT)
        }
    }

    handleInputChange(e) {
        this.setState({ ...this.state, config: { ...this.state.config, [e.target.name]: e.target.value } });
    }

    handleCreateGameClick() {
        this.props.createGame(this.state.config.gameId, this.state.config.mode);
    }

    handleLaunchGame() {
        const { gameId, userToken, redirectUrl } = this.state.config;
        this.props.launchGame(gameId, userToken, redirectUrl);
    }

    renderConfigParamsForm() {
        const { gameId, userToken, redirectUrl, mode } = this.state.config;

        return (
            <div>
                <div>
                    <FormRow>
                        <label>game id:</label>
                        <input type="text" name="gameId" value={gameId} onChange={this.handleInputChange} />
                    </FormRow>
                    <FormRow>
                        <label>user token:</label>
                        <input type="text" name="userToken" value={userToken} onChange={this.handleInputChange} />

                    </FormRow>
                    <FormRow>
                        <label>lobby url:</label>
                        <input type="text" name="redirectUrl" value={redirectUrl} onChange={this.handleInputChange} />
                    </FormRow>
                    <FormRow>
                        <label>mode (0/1):</label>
                        <input type="text" name="mode" value={mode} onChange={this.handleInputChange} />
                    </FormRow>
                </div>
            </div>
        );
    }

    renderCreateGameScreen() {
        return (
            <Window>
                <Container>
                    <h2>Create Game Screen</h2>
                    {this.renderConfigParamsForm()}
                    <Button onClick={() => this.handleCreateGameClick()}>Create</Button>
                    <Button onClick={() => this.handleLaunchGame()}>Launch</Button>
                </Container>
            </Window>
        )
    }

    handlePreloaderUnmounting() {
        clearTimeout(this.progressTimeout);
        requestAnimationFrame(() => {
            this.setState({ ...this.state, progressValue: 1 });
        });

        this.loadingCompleteTimeout = setTimeout(() => {
            requestAnimationFrame(() => {
                this.setState({ ...this.state, loadingComplete: true });
            });
        }, PROGRESS_TIMEOUT);
    }

    renderGameScreen() {
        return (
            <Suspense fallback={<Preloader progress={this.state.progressValue} onUnmount={() => this.handlePreloaderUnmounting()} />}>
                {!this.state.loadingComplete ? <Preloader progress={this.state.progressValue} /> : null}
                <GameScreen componentLoaded={this.state.loadingComplete} />
            </Suspense>
        );
    }

    render() {
        const params = qs.parse(window.location.search);

        if (params.sessionId) {
            return this.renderGameScreen();
        }
        else {
            return this.renderCreateGameScreen();
        }
    }
}

export default connect(null, { createGame, launchGame, initAppParams, fetchInitialData, unmuteSounds, muteSounds, playButtonClickSound })(LaunchScreen);
